import React from 'react';
import './Policy.css';

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <h2>Privacy Policy</h2>
    <p>
      At Ferona Resorts, accessible from https://feronaresorts.com, we prioritize your privacy. This Privacy Policy document outlines the types of information collected by Ferona Resorts and how we use and protect it.
    </p>

    <h3>1. Information We Collect</h3>
    <p>
      We may collect and process the following types of data:
    </p>
    <ul>
      <li><strong>Personal Data:</strong> Information such as name, email address, and contact details provided voluntarily by users.</li>
      <li><strong>Usage Data:</strong> Information about how our website is accessed and used, including IP addresses, browser type, and interaction with our website.</li>
    </ul>

    <h3>2. How We Use Your Information</h3>
    <p>
      Collected data is used for purposes including:
    </p>
    <ul>
      <li>Improving website functionality and services.</li>
      <li>Communicating updates, offers, or relevant information.</li>
      <li>Analyzing user behavior to optimize our services.</li>
    </ul>

    <h3>3. Data Protection Rights</h3>
    <p>
      We strive to ensure data security and provide you with the following rights:
    </p>
    <ul>
      <li><strong>Access:</strong> You can request a copy of your personal data.</li>
      <li><strong>Correction:</strong> You can request that we correct any inaccurate data.</li>
      <li><strong>Deletion:</strong> You may request the deletion of your data under certain conditions.</li>
    </ul>

    <h3>4. Security</h3>
    <p>
      We implement measures to protect your personal information from unauthorized access or disclosure. However, no method of transmission over the internet is entirely secure.
    </p>

    <h3>5. Changes to This Policy</h3>
    <p>
      This policy may be updated periodically. Changes will be posted on this page, and we encourage you to review this page regularly.
    </p>
  </div>
);

export default PrivacyPolicy;
