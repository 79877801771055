import React from 'react';
import './TermsConditions.css';

const TermsConditions = () => (
  <div className="terms-conditions">
    <h2>Terms and Conditions</h2>
    <p>
      Welcome to Ferona Resorts! These terms and conditions outline the rules and regulations for the use of our website, located at https://feronaresorts.com.
    </p>
    <p>
      By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Ferona Resorts if you do not agree with the terms and conditions stated on this page.
    </p>

    <h3>1. Intellectual Property Rights</h3>
    <p>
      Unless otherwise stated, Ferona Resorts and/or its licensors own the intellectual property rights for all material on this website. You may view and/or print pages from https://feronaresorts.com for your own personal use subject to restrictions set in these terms and conditions.
    </p>

    <h3>2. Restrictions</h3>
    <p>You are specifically restricted from the following:</p>
    <ul>
      <li>Republishing any material from this website without prior consent.</li>
      <li>Selling, sublicensing, or otherwise commercializing any website material.</li>
      <li>Using this website in a way that is damaging, illegal, or harmful to others.</li>
    </ul>

    <h3>3. User Obligations</h3>
    <p>As a user, you agree to:</p>
    <ul>
      <li>Use the website only for lawful purposes.</li>
      <li>Avoid any actions that may disrupt the website’s functionality.</li>
      <li>Refrain from posting or sharing any harmful or objectionable content.</li>
    </ul>

    <h3>4. Limitation of Liability</h3>
    <p>
      Ferona Resorts will not be held liable for any damages arising from the use of this website or inability to use this website. Our liability is limited to the maximum extent permitted by law.
    </p>

    <h3>5. Changes to These Terms</h3>
    <p>
      We reserve the right to modify these terms at any time. By using this website, you agree to review these terms regularly.
    </p>

    <h3>6. Governing Law</h3>
    <p>
      These terms shall be governed by the laws of Australia, and you submit to the exclusive jurisdiction of the courts in that location.
    </p>
  </div>
);

export default TermsConditions;
