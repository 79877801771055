import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <p>feronaresorts.com © 2024</p>
    <p>Discover Australia's casino hotels and enjoy an unforgettable stay!</p>
    <div className="footer-links">
      <Link to="/privacy-policy">Privacy Policy</Link> | 
      <Link to="/terms-conditions">Terms & Conditions</Link> | 
      <Link to="/cookie-policy">Cookie Policy</Link> | 
      <Link to="/casino-hotels">Casino Hotels</Link> | {/* Yeni bağlantı */}
      <Link to="/casino-hotels-2">Casino Hotels 2</Link>
    </div>
  </footer>
);

export default Footer;
