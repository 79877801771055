import React from 'react';
import './Policy.css';

const CookiePolicy = () => (
  <div className="cookie-policy">
    <h2>Cookie Policy</h2>
    <p>
      This Cookie Policy explains how Ferona Resorts uses cookies and similar technologies on https://feronaresorts.com.
    </p>
    
    <h3>1. What Are Cookies?</h3>
    <p>
      Cookies are small data files that are stored on your device when you visit a website. They help websites function efficiently and collect analytical data to enhance user experience.
    </p>
    
    <h3>2. How We Use Cookies</h3>
    <p>
      We use cookies to:
    </p>
    <ul>
      <li>Remember your preferences and personalize your experience.</li>
      <li>Analyze website usage and improve our content and services.</li>
      <li>Serve targeted advertising based on your browsing behavior.</li>
    </ul>
    
    <h3>3. Types of Cookies We Use</h3>
    <p>
      The types of cookies we may use include:
    </p>
    <ul>
      <li><strong>Essential Cookies:</strong> Necessary for basic website functionality.</li>
      <li><strong>Performance Cookies:</strong> Used to gather data about how users interact with our website.</li>
      <li><strong>Advertising Cookies:</strong> Used to deliver ads relevant to you.</li>
    </ul>
    
    <h3>4. Managing Your Cookie Preferences</h3>
    <p>
      You have the option to accept or decline cookies. Most browsers automatically accept cookies, but you can modify your settings to decline them if you prefer. However, please note that disabling cookies may impact your experience on our website.
    </p>
    
    <h3>5. Changes to This Cookie Policy</h3>
    <p>
      We may update this Cookie Policy from time to time. Changes will be posted on this page, and your continued use of our website constitutes acceptance of any changes.
    </p>
  </div>
);

export default CookiePolicy;
